import { AuthenticationStatus } from '@enums'

export const getAuthenticationStatusString = (
  status?: AuthenticationStatus
): string => {
  if (status !== undefined && status !== null)
    return getKeyByValue(AuthenticationStatus, status) ?? ''
  return getKeyByValue(AuthenticationStatus, AuthenticationStatus.FAILED) ?? ''
}

export const getKeyByValue = (object: any, value: any) =>
  Object.keys(object).find((key) => object[key] === value)
