import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import {
  AuthenticationModel,
  CommonAuthProcessProps,
  SetupModel
} from '@models'
import { AuthenticationStatus } from '@enums'
import AuthenticationApi from '@utils/authentication-api'
import ApiService from '@utils/payer-auth-api'

const useInswitchAuth = ({
  initialAuthentication,
  showBrand
}: CommonAuthProcessProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [authentication, setAuthentication] = useState<AuthenticationModel>()
  const [setup, setSetup] = useState<SetupModel>()
  const [showNotificationForm, setShowNotificationForm] =
    useState<boolean>(false)

  const navigate = useNavigate()
  const { id } = useParams()
  const checkedId = id ?? ''

  const withLoading = async <T extends any>(
    asyncFunction: () => Promise<T>
  ): Promise<T | undefined> => {
    setIsLoading(true)
    try {
      const result = await asyncFunction()
      return result
    } catch (error) {
      handleError(error)
      return undefined
    } finally {
      setIsLoading(false)
    }
  }

  const checkIfNotificationFormIsDisplayed = (
    currentAuthenticationValues: AuthenticationModel
  ) => {
    const externalReturnUrl =
      currentAuthenticationValues?.externalReturnUrl ?? ''
    const isRedirectNotification = externalReturnUrl.trim() !== ''

    if (isRedirectNotification) setShowNotificationForm(true)
    else emitEventResult(currentAuthenticationValues)
  }

  const emitEventResult = (
    currentAuthenticationValues: AuthenticationModel
  ) => {
    AuthenticationApi.emitAuthenticationResult(currentAuthenticationValues)
  }

  const handleError = (error?: unknown) => {
    if (error) Sentry.captureException(error)

    navigate('/error', { state: { showBrand } })
  }

  const getSetupPayerParams = async () =>
    withLoading(async () => {
      const responseSetup = await ApiService.post<SetupModel>(
        `setup/${checkedId}`,
        {}
      )

      if (!responseSetup) {
        handleError()
        return
      }

      setSetup(responseSetup)
      setAuthentication(responseSetup.authentication)

      if (responseSetup.authentication.status !== AuthenticationStatus.PENDING)
        checkIfNotificationFormIsDisplayed(responseSetup.authentication)
    })

  const checkAuthenticationResult = async (_: any) =>
    withLoading(async () => {
      {
        // await for the authentication to be updated
        await new Promise((resolve) => setTimeout(resolve, 2000))

        const responseAuthentication = await AuthenticationApi.get(checkedId)

        if (!responseAuthentication) {
          handleError()
          return
        }

        setAuthentication(responseAuthentication)
        checkIfNotificationFormIsDisplayed(responseAuthentication)
      }
    })

  useEffect(() => {
    if (
      initialAuthentication &&
      initialAuthentication.status !== AuthenticationStatus.PENDING
    ) {
      setAuthentication(initialAuthentication)
      setIsLoading(false)
      checkIfNotificationFormIsDisplayed(initialAuthentication)
    } else {
      getSetupPayerParams()
    }
  }, [])

  return {
    isLoading,
    authentication,
    setup,
    showNotificationForm,
    checkAuthenticationResult
  }
}

export default useInswitchAuth
