import { useTranslation } from 'react-i18next'
import { AuthenticationModel } from '@models'
import { AuthenticationStatus, AuthenticationStep } from '@enums'
import {
  ONE_MOMENT,
  PROCESSING_SECURE_TRANSACTION
} from '@constants/translationsKeys'
import { Loading, Result } from '@components'
import { NotificationForm } from '@forms'
import { usePaywayAuth } from '../../hooks'
import { DeviceDataCollectorForm, AdvancedAuthenticationForm } from './forms'
import { getAuthenticationStatusString } from '@utils/authentication-helpers'

type Props = {
  showBrand: boolean
  initialAuthentication?: AuthenticationModel
}

const PaywayAuth: React.FC<Props> = ({ showBrand, initialAuthentication }) => {
  const { t } = useTranslation()
  const {
    isLoading,
    showResult,
    authentication,
    step,
    setup,
    enroll,
    checkDeviceDataCollectorResult,
    checkAuthenticationResult
  } = usePaywayAuth({ showBrand, initialAuthentication })

  const LoadingComponent = (
    <Loading
      showBrand={showBrand}
      message={t(ONE_MOMENT, { extra: '...' })}
      description={t(PROCESSING_SECURE_TRANSACTION, { extra: '...' })}
    />
  )

  if (isLoading) return LoadingComponent

  if (showResult)
    return (
      <Result
        isSuccessful={authentication?.status === AuthenticationStatus.SUCCESS}
        showBrand={showBrand}
        message={authentication?.statusMsg ?? ''}
      />
    )

  switch (step) {
    case AuthenticationStep.Enroll:
      return (
        <>
          {LoadingComponent}
          <DeviceDataCollectorForm
            accessToken={setup?.accessToken ?? ''}
            collectionURL={setup?.collectionUrl ?? ''}
            onComplete={checkDeviceDataCollectorResult}
          />
        </>
      )
    case AuthenticationStep.StepUp:
      return (
        <AdvancedAuthenticationForm
          accessToken={enroll?.accessToken ?? ''}
          collectionURL={enroll?.stepUpUrl ?? ''}
          onComplete={checkAuthenticationResult}
        />
      )
    case AuthenticationStep.Notification:
      return (
        <>
          {LoadingComponent}
          <NotificationForm
            status={getAuthenticationStatusString(authentication?.status)}
            message={authentication?.statusMsg ?? ''}
            authenticationId={authentication!.authenticationId}
            orderId={authentication!.orderId}
            orderAmount={authentication!.orderAmount}
            redirectUrl={authentication!.externalReturnUrl}
          />
        </>
      )
    default:
      return LoadingComponent
  }
}

export default PaywayAuth
