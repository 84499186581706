import { useTranslation } from 'react-i18next'
import { AuthenticationModel } from '@models'
import {
  ONE_MOMENT,
  PROCESSING_SECURE_TRANSACTION
} from '@constants/translationsKeys'
import { AuthenticationStatus } from '@enums'
import { getAuthenticationStatusString } from '@utils/authentication-helpers'
import { Loading, Result } from '@components'
import { NotificationForm } from '@forms'
import { useInswitchAuth } from '../../hooks'
import { ChallengeIframe } from './components'

interface Props {
  showBrand: boolean
  initialAuthentication?: AuthenticationModel
}

interface LoadingComponentProps {
  showBrand: boolean
  t: (key: string, options?: any) => string
}

const LoadingComponent: React.FC<LoadingComponentProps> = ({
  showBrand,
  t
}) => (
  <Loading
    showBrand={showBrand}
    message={t(ONE_MOMENT, { extra: '...' })}
    description={t(PROCESSING_SECURE_TRANSACTION, { extra: '...' })}
  />
)

const InswitchAuth: React.FC<Props> = ({
  showBrand,
  initialAuthentication
}) => {
  const { t } = useTranslation()
  const {
    isLoading,
    authentication,
    setup,
    showNotificationForm,
    checkAuthenticationResult
  } = useInswitchAuth({ showBrand, initialAuthentication })

  if (isLoading) return <LoadingComponent showBrand={showBrand} t={t} />

  if (authentication?.status === AuthenticationStatus.PENDING) {
    return (
      <ChallengeIframe
        collectionURL={setup?.collectionUrl ?? ''}
        checkAuthenticationResult={checkAuthenticationResult}
      />
    )
  }

  if (showNotificationForm) {
    return (
      <>
        <LoadingComponent showBrand={showBrand} t={t} />
        <NotificationForm
          status={getAuthenticationStatusString(authentication?.status)}
          message={authentication?.statusMsg!}
          authenticationId={authentication!.authenticationId}
          orderId={authentication!.orderId}
          orderAmount={authentication!.orderAmount}
          redirectUrl={authentication!.externalReturnUrl}
        />
      </>
    )
  }

  return (
    <Result
      isSuccessful={authentication?.status === AuthenticationStatus.SUCCESS}
      showBrand={showBrand}
      message={authentication?.statusMsg ?? ''}
    />
  )
}

export default InswitchAuth
