import { useTranslation } from 'react-i18next'
import { AuthenticationProvider } from '@enums'
import { Loading } from '@components'
import {
  ONE_MOMENT,
  PROCESSING_SECURE_TRANSACTION
} from '@constants/translationsKeys'
import { useInitialConfig, useURLQueryParams } from './hooks'
import {
  CybersourceAuth,
  InswitchAuth,
  PaywayAuth,
  PowertranzAuth
} from './components'

export const Authentication: React.FC = () => {
  const { t } = useTranslation()
  const { showBrand } = useURLQueryParams()
  const { authentication, isLoading } = useInitialConfig({ showBrand })

  if (isLoading) {
    return (
      <Loading
        showBrand={showBrand}
        message={t(ONE_MOMENT, { extra: '...' })}
        description={t(PROCESSING_SECURE_TRANSACTION, { extra: '...' })}
      />
    )
  }

  const authComponents = {
    [AuthenticationProvider.POWERTRANZ]: PowertranzAuth,
    [AuthenticationProvider.PAYWAY]: PaywayAuth,
    [AuthenticationProvider.INSWITCH]: InswitchAuth,
    [AuthenticationProvider.CYBERSOURCE]: CybersourceAuth,
    [AuthenticationProvider.SANDBOX]: CybersourceAuth
  }

  const AuthComponent = authentication?.provider
    ? authComponents[authentication.provider]
    : CybersourceAuth

  return (
    <AuthComponent
      showBrand={showBrand}
      initialAuthentication={authentication}
    />
  )
}
export default Authentication
