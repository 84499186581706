import { useEffect } from 'react'
import cn from 'classnames'

import styles from './styles'

const {
  P3F_INSWITCH_CHALLENGE_CALLBACK_ALLOWED_ORIGINS:
    INSWITCH_CHALLENGE_CALLBACK_ALLOWED_ORIGINS
} = p3fenv

type Props = {
  collectionURL: string
  checkAuthenticationResult: (result: any) => Promise<void>
}

const ChallengeIframe: React.FC<Props> = ({
  collectionURL,
  checkAuthenticationResult
}) => {
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const validOrigins =
        INSWITCH_CHALLENGE_CALLBACK_ALLOWED_ORIGINS.split(',')
      if (validOrigins.includes(event.origin)) {
        const content = JSON.parse(event.data as string)
        checkAuthenticationResult(content)
      }
    }

    window.addEventListener('message', handleMessage, false)
    return () => {
      window.removeEventListener('message', handleMessage, false)
    }
  }, [checkAuthenticationResult])

  return (
    <div id="inswitch-challenge" className={cn('inswitch-layout', styles)}>
      <iframe src={collectionURL} id="inswitch-challenge-iframe" />
    </div>
  )
}

export default ChallengeIframe
