import classNames from 'classnames'

// ASSETS
import MainBrandIcon from '@assets/icons/main-brand.svg?react'

// STYLES
import styles from './styles'

type Props = {
  children: React.ReactNode
  showBrand?: boolean
  showAsAPlugin?: boolean
}

const MainContainer: React.FC<Props> = ({
  children,
  showBrand = true,
  showAsAPlugin = true
}) => (
  <div className={classNames(styles, { 'hide-brand': !showBrand })}>
    {!showAsAPlugin ? (
      <>
        {showBrand && <MainBrandIcon className="main-brand-icon" />}
        {children}
      </>
    ) : (
      <div className={classNames('plugin-container')}>
        <div className={classNames('header')}>
          <MainBrandIcon className="main-brand-icon" />
        </div>

        <div className="content">{children}</div>
      </div>
    )}
  </div>
)

export default MainContainer
