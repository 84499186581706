// ASSETS
import Circles from '@assets/icons/circles.svg?react'

// STYLES
import styles from './styles'

type Props = {
  size?: number
}

const LoadingSpinner: React.FC<Props> = ({ size = 40 }) => (
  <Circles className={styles} width={size} height={size} />
)

export default LoadingSpinner
